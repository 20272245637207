import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import moment from 'moment-timezone';
import UserAvatar from '../Common/UserAvatar';
import Pill from '../Common/Pill';
import RemoveMembershipButton from '../Common/RemoveMembershipButton';
import tableIcons from '../../utils/muiIcons';
import { capitalizeFirst } from '../../utils/stringUtils';
import {
  getClassNameForSubscriptionStatus,
  getFriendlyNameForUserStatus,
} from '../../utils/subscriptionUtils';
import { rolesById } from '../../enums/roles';

class UserTable extends Component {
  render() {
    const {
      users,
      title = 'Search Results',
      showTitle = true,
      onRowClick,
      statusColumn = 'subscription_status',
      search = false,
      showRemoveMembership = false,
      isTeamView,
      showRole = false,
      statusLabel = 'Login Status',
      isInternalAdmin = false,
      teamName = '',
    } = this.props;
    const avatarColumn = {
      title: '',
      field: 'profile_photo',
      render: (rowData) => <UserAvatar user={rowData} />,
      sorting: false,
      width: null,
      cellStyle: {
        width: 100,
        maxWidth: 100,
      },
      headerStyle: {
        color: 'red',
      },
    };
    const columns = [
      { title: 'Last Name', field: 'last_name', defaultSort: 'asc' },
      { title: 'First Name', field: 'first_name' },
      {
        title: statusLabel,
        field: statusColumn,
        render: (rowData) => (
          <Pill
            title={
              isTeamView
                ? getFriendlyNameForUserStatus(rowData[statusColumn])
                : capitalizeFirst(rowData[statusColumn])
            }
            className={getClassNameForSubscriptionStatus(rowData[statusColumn])}
          />
        ),
      },
      {
        title: 'Last App Activity',
        field: 'last_login',
        type: 'date',
        render: (rowData) =>
          rowData.last_login ? moment(rowData.last_login).format('MMMM D, YYYY') : '--',
        customSort: (a, b) => {
          if (!a.last_login && !b.last_login) {
            return 0;
          } if (a.last_login && !b.last_login) {
            return 1;
          } if (!a.last_login && b.last_login) {
            return -1;
          }
          const momA = moment(a.last_login);
          const momB = moment(b.last_login);
          if (momA.isBefore(momB)) {
            return -1;
          } if (momB.isBefore(momA)) {
            return 1;
          }
          return 0;
        },
      },
      {
        title: 'Joined Date',
        field: 'inserted_at',
        type: 'date',
        render: (rowData) =>
            rowData.inserted_at ? moment(rowData.inserted_at).format('MMMM D, YYYY') : '',
          customSort: (a, b) => {
            if (!a.inserted_at && !b.inserted_at) {
              return 0;
            } if (a.inserted_at && !b.inserted_at) {
              return 1;
            } if (!a.inserted_at && b.inserted_at) {
              return -1;
            }
            const momA = moment(a.inserted_at);
            const momB = moment(b.inserted_at);
            if (momA.isBefore(momB)) {
              return -1;
            } if (momB.isBefore(momA)) {
              return 1;
            }
            return 0;
          },
      },
    ];
    columns.splice(2, 0, { title: 'Email', field: 'email', hidden: isTeamView, export: true });
    if (!isTeamView) {

    } else if (showRole) {
      columns.splice(2, 0, {
        title: 'Role',
        field: 'role_id',
        render: (rowData) => rolesById[rowData.role_id],
      });
    }

    if (showRemoveMembership) {
      columns.push({
        title: '',
        field: '',
        disableClick: true,
        render: (rowData) => <RemoveMembershipButton user={rowData} />,
      });
    }

    return (
      <MaterialTable
        columns={columns}
        data={users}
        title={title}
        key={users.length} // https://github.com/mbrn/material-table/issues/1480
        options={{
          padding: 'dense',
          pageSize: users.length < 20 ? users.length : 20,
          search,
          paging: users.length > 20,
          sorting: true,
          thirdSortClick: false,
          showTitle,
          toolbar: showTitle,
          exportButton: isInternalAdmin && isTeamView,
          exportFileName: `${teamName.toLowerCase().replace(' ', '_')}_${title.toLowerCase()}_${moment().format('YYYY-MM-DD_HH_mm_ss')}`,
          exportAllData: true,
        }}
        style={{
          backgroundColor: '#f7f7f7',
        }}
        icons={tableIcons}
        onRowClick={onRowClick}
      />
    );
  }
}

UserTable.propTypes = {
  title: PropTypes.string,
  onRowClick: PropTypes.func,
  users: PropTypes.instanceOf(Array),
  statusColumn: PropTypes.string,
  search: PropTypes.bool,
  showRemoveMembership: PropTypes.bool,
  showTitle: PropTypes.bool,
  isTeamView: PropTypes.bool,
  showRole: PropTypes.bool,
  teamName: PropTypes.string,
  isInternalAdmin: PropTypes.bool,
};

export default UserTable;
