import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { OnChange } from 'react-final-form-listeners';
import { submissionError } from '../actions/formActions';
import renderCheckboxForField from './helpers/renderCheckboxForField';

const CustomToggleForm = ({
  initialValues,
  label,
  name,
  doCustomToggle,
  isDisabled = true,
}) => {
  const handleToggle = (value) => {
    doCustomToggle(value);
  };

  return (
    <Form
      onSubmit={() => {}}
      initialValues={initialValues}
      keepDirtyOnReinitialize
      render={() => {
        return (
          <form className="inline-form">
            <Row>
              <Col>
                <Field
                  name={name}
                  component={renderCheckboxForField}
                  {
                    ...{
                      itemProps: {},
                      checkboxIcon: '',
                      checkboxIconUnchecked: '',
                      inputProps: {
                        disabled: isDisabled,
                      },
                      labelClassName: 'switch switch-lg',
                      label,
                    }
                  }
                />
                <OnChange name={name}>
                  {handleToggle}
                </OnChange>
              </Col>
            </Row>
          </form>
        );
      }}
    />
  );
};

CustomToggleForm.propTypes = {
  initialValues: PropTypes.object,
  doCustomToggle: PropTypes.func,
  isDisabled: PropTypes.bool,
};

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
  showSubmissionError: (errors, scroll) => dispatch(submissionError(errors, scroll)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomToggleForm);
