import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { Row, Col, Button } from 'reactstrap';
import { OnChange } from 'react-final-form-listeners';
import MaterialTable, { MTableToolbar } from 'material-table';
import tableIcons from '../utils/muiIcons';
import renderCheckboxForField from './helpers/renderCheckboxForField';
import Pill from '../components/Common/Pill';
import {
  getClassNameForSubscriptionStatus,
  getFriendlyNameForUserStatus,
} from '../utils/subscriptionUtils';
import RemoveMembershipModal from '../modals/RemoveMembershipModal';
import { renderColumnHeaderWithTooltip } from '../utils/tableUtils';
import moment from 'moment-timezone';

const validate = (values) => {
  const errors = {};
  if (Object.keys(values).length === 0) {
    errors.user_0 = 'Please select at least one client';
  }
  return errors;
};

class PendingClientsForm extends Component {
  constructor(props) {
    super(props);
    const { users } = this.props;
    this.state = {
      select: 'All',
      users,
      removeMembershipModalOpen: false,
      usersToRemove: [],
    };
  }

  componentDidUpdate(prevProps) {
      const { users } = this.props;

      if (JSON.stringify(users) !== JSON.stringify(prevProps.users)) {
        this.setState({
            users,
        });
      }
    }

  selectAllOrNone = (form) => {
    const { select, users } = this.state;
    const checked = select === 'All';
    for (let i = 0; i < users.length; i += 1) {
      form.change(`user_${users[i].id}`, checked);
    }
    setTimeout(() => {
      this.setState({
        select: select === 'All' ? 'None' : 'All',
      });
    });
  };

  checkValues = (value, values, id, form) => {
    if (!value) {
      this.setState({
        select: 'All',
      });
    } else {
      values[`user_${id}`] = true;
      const { users } = this.state;
      let selected = 0;
      for (let i = 0; i < users.length; i += 1) {
        if (values[`user_${users[i].id}`]) {
          selected += 1;
        }
      }
      this.setState({
        select: selected === users.length ? 'None' : 'All',
      });
    }
    const poc = document.getElementsByName(`point_of_contact_${id}`)[0];
    if (value) {
      if (poc !== undefined) {
        poc.disabled = false;
      }
    } else {
      if (poc !== undefined) {
        form.change(`point_of_contact_${id}`, false);
        poc.disabled = true;
      }
    }
  };

  removeClients = (values) => {
    const { users, showSubmissionError } = this.props;
    const usersToRemove = [];
    for (let i = 0; i < users.length; i += 1) {
      if (values[`user_${users[i].id}`] !== undefined && values[`user_${users[i].id}`] === true) {
        usersToRemove.push(users[i]);
      }
    }
    if (usersToRemove.length === 0) {
      showSubmissionError({ users: 'You must select at least one client to remove' });
    } else {
      this.setState(
        {
          usersToRemove,
        },
        () => {
          this.toggleRemoveMembershipModal();
        }
      );
    }
  };

  toggleRemoveMembershipModal = () => {
    const { removeMembershipModalOpen } = this.state;
    this.setState({
      removeMembershipModalOpen: !removeMembershipModalOpen,
    });
  };

  render() {
    const {
      initialValues,
      onSubmit,
      showSubmissionError,
      team,
      isInternalAdmin,
      toggleTab,
      onRowClick,
      showStatus = true,
      pageSize = 25,
      showRemove = true,
      buttonText = 'Send Invitations',
      title = 'Manage Invites',
      isAdminForm = false,
    } = this.props;
    const { select, users, removeMembershipModalOpen, usersToRemove } = this.state;

    const extraCols = [];
    if (showStatus) {
      extraCols.push({
        title: 'Client Status',
        field: 'user_role_status',
        render: (rowData) => (
          <Pill
            title={getFriendlyNameForUserStatus(rowData.user_role_status)}
            className={getClassNameForSubscriptionStatus(rowData.user_role_status)}
          />
        ),
      });
    }
    if (isAdminForm) {
      extraCols.push({
        title: renderColumnHeaderWithTooltip('2', 'Point of Contact', 'POINT_OF_CONTACT', ''),
        render: (rowData) => (
          <div className="d-flex align-items-center checkbox-row">
            <Field
              name={`point_of_contact_${rowData.id}`}
              component={renderCheckboxForField}
              {...{
                itemProps: {},
                checkboxIcon: 'fa-check',
                checkboxIconUnchecked: 'fa-close',
                inputProps: {
                  disabled: true,
                },
              }}
            />
            <OnChange name={`point_of_contact_${rowData.id}`} />
          </div>
        ),
        sorting: false,
        width: null,
        cellStyle: {
          textAlign: 'center',
          justifyContent: 'center',
          display: 'flex',
        },
        headerStyle: {
          textAlign: 'center',
          justifyContent: 'center',
          display: 'flex',
        },
      });
    }
    return (
      <div>
        <Form
          onSubmit={(values) => {
            const errors = validate(values);
            if (Object.keys(errors).length === 0) {
              onSubmit(values);
            } else {
              showSubmissionError(errors);
            }
          }}
          initialValues={initialValues}
          keepDirtyOnReinitialize
          render={({ handleSubmit, form, values }) => (
            <form onSubmit={handleSubmit} className="inline-form">
              <Row className="mt-3">
                <Col xs={12}>
                  <MaterialTable
                    columns={[
                      {
                        title: 'Select',
                        render: (rowData) => (
                          <div className="d-flex align-items-center checkbox-row">
                            <Field
                              name={`user_${rowData.id}`}
                              component={renderCheckboxForField}
                              {...{
                                itemProps: {},
                                checkboxIcon: 'fa-check',
                                checkboxIconUnchecked: 'fa-close',
                                inputProps: {},
                              }}
                            />
                            <OnChange name={`user_${rowData.id}`}>
                              {(value) => {
                                this.checkValues(value, values, rowData.id, form);
                              }}
                            </OnChange>
                          </div>
                        ),
                        sorting: false,
                        width: null,
                        cellStyle: {
                          width: 100,
                          maxWidth: 100,
                        },
                      },
                      { title: 'Last Name', field: 'last_name', defaultSort: 'asc' },
                      { title: 'First Name', field: 'first_name' },
                      {
                        title: 'Joined Date',
                        field: 'inserted_at',
                        type: 'date',
                        render: (rowData) =>
                            rowData.inserted_at ? moment(rowData.inserted_at).format('MMMM D, YYYY') : '',
                          customSort: (a, b) => {
                            if (!a.inserted_at && !b.inserted_at) {
                              return 0;
                            } if (a.inserted_at && !b.inserted_at) {
                              return 1;
                            } if (!a.inserted_at && b.inserted_at) {
                              return -1;
                            }
                            const momA = moment(a.inserted_at);
                            const momB = moment(b.inserted_at);
                            if (momA.isBefore(momB)) {
                              return -1;
                            } if (momB.isBefore(momA)) {
                              return 1;
                            }
                            return 0;
                          },
                      },
                      ...extraCols,
                    ]}
                    data={users}
                    title={title}
                    key={users.length} // https://github.com/mbrn/material-table/issues/1480
                    options={{
                      padding: 'dense',
                      pageSize,
                      search: true,
                      paging: users.length > pageSize,
                      sorting: true,
                      thirdSortClick: false,
                      showTitle: true,
                      toolbar: true,
                      pageSizeOptions: [pageSize, 50, 100],
                      debounceInterval: 600,
                      draggable: false,
                    }}
                    style={{
                      backgroundColor: '#f7f7f7',
                    }}
                    icons={tableIcons}
                    onSearchChange={(x) => {
                      if (x !== '') {
                        const searchBar = document.querySelector('#mt-toolbar-container input');
                        if (!searchBar) return;
                        setTimeout(() => searchBar.focus());
                      }
                    }}
                    onRowClick={(e, rowData) => {
                      if (
                        e.target instanceof HTMLInputElement ||
                        e.target instanceof HTMLSpanElement
                      ) {
                        e.stopPropagation();
                      } else {
                        onRowClick(rowData);
                      }
                    }}
                    components={{
                      Toolbar: (props) => (
                        <div id="mt-toolbar-container">
                          <MTableToolbar {...props} />
                          <div className="ml-3">
                            <span
                              className="span-link"
                              onClick={() => this.selectAllOrNone(form, values)}
                            >
                              Select {select}
                            </span>
                          </div>
                        </div>
                      ),
                    }}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col className="d-flex justify-content-end">
                  {showRemove && (
                    <Button
                      color="secondary"
                      size="lg"
                      className="ml-3 fixed-width-xmd"
                      onClick={() => this.removeClients(values)}
                    >
                      Remove Clients
                    </Button>
                  )}
                  <Button color="primary" size="lg" type="submit" className="ml-3 fixed-width-xmd">
                    {buttonText}
                  </Button>
                </Col>
              </Row>
              <RemoveMembershipModal
                users={usersToRemove}
                isOpen={removeMembershipModalOpen}
                toggle={this.toggleRemoveMembershipModal}
                isPending={true}
                team={team}
                isInternalAdmin={isInternalAdmin}
                toggleTab={toggleTab}
              />
            </form>
          )}
        />
      </div>
    );
  }
}

PendingClientsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.instanceOf(Object),
  showSubmissionError: PropTypes.func,
  onCancel: PropTypes.func,
  users: PropTypes.instanceOf(Array),
  team: PropTypes.instanceOf(Object),
  isInternalAdmin: PropTypes.bool,
  onRowClick: PropTypes.func,
  showStatus: PropTypes.bool,
  pageSize: PropTypes.number,
  showRemove: PropTypes.bool,
  buttonText: PropTypes.string,
  title: PropTypes.string,
};

export default PendingClientsForm;
