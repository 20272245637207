import {
  CLEAR_PENDING_CLIENTS,
  GET_TEAM,
  GET_DASHBOARD,
  GET_ENGAGEMENT_SUMMARY,
  CLEAR_ENGAGEMENT_SUMMARY_CSV,
  GET_CUSTOM_FEATURES,
  GET_HIDDEN_FEATURES,
} from '../actions/teamActions';
import {
  AUTH_LOGOUT,
  PUT_STREAM_TOKEN,
  CLEAR_STREAM_TOKENS,
  STREAM_UNREAD_COUNT,
  SWITCH_TEAMS,
} from '../actions/authActions';
import {
  GET_TEAM_SUCCESS,
  TEAM_PROFILE_SUCCESS,
  TEAM_SEARCH_SUCCESS,
  GET_TEAM_MEMBERSHIP_SUCCESS,
  UPDATE_TEAM_PAYMENT_PROFILE_SUCCESS,
  UPDATE_MEMBERSHIP_SUCCESS,
  CANCEL_PLAN_SUCCESS,
  REACTIVATE_MEMBERSHIP_SUCCESS,
  GET_DASHBOARD_SUCCESS,
  GET_ENGAGEMENT_SUMMARY_SUCCESS,
  GET_ENGAGEMENT_SUMMARY_CSV_SUCCESS,
  GET_CLIENT_STATUS_AND_GOAL_DISTRIBUTION_SUCCESS,
  GET_CUSTOM_FEATURES_SUCCESS,
  GET_HIDDEN_FEATURES_SUCCESS,
  DELETE_HIDDEN_FEATURE_SUCCESS,
  GET_INVITATION_TOKENS_SUCCESS,
  GET_CLIENTS_REDEEMED_SUCCESS,
} from '../apiActions/teamApiActions';
import { PENDING_CLIENT_SEARCH_SUCCESS, ADMIN_SEARCH_SUCCESS } from '../apiActions/userApiActions';
import { randomIntFromInterval } from '../utils/dataGeneratorUtils';
import { GET_GROUP_DETAILS_SUCCESS } from '../apiActions/groupApiActions';

const initialTeamState = {
  clientsRedeemed: [],
  mainToken: null,
}; // team;

export default (state = initialTeamState, action) => {
  switch (action.type) {
    case GET_TEAM: {
      return {
        team_id: action.team_id,
      };
    }
    case GET_DASHBOARD: {
      return {
        ...state,
        dashboard: undefined,
      };
    }
    case GET_ENGAGEMENT_SUMMARY: {
      let clearData = true;
      if (action.clearData !== undefined) {
        clearData = action.clearData;
      }
      return {
        ...state,
        engagementSummary: clearData ? undefined : state.engagementSummary,
      };
    }
    case GET_TEAM_SUCCESS: {
      const { primary_logo_path = '' } = action.data;
      let cacheKiller = '';
      if (primary_logo_path !== '') {
        cacheKiller = `${primary_logo_path}?${randomIntFromInterval(0, 9999999999999999999)}`;
      }
      return {
        ...state,
        settings: {
          ...action.data,
          primary_logo_path: cacheKiller,
        },
      };
    }
    case TEAM_PROFILE_SUCCESS: {
      return { ...state, profile: action.data };
    }
    case TEAM_SEARCH_SUCCESS: {
      return { ...state, teamSearchResults: action.data };
    }
    case ADMIN_SEARCH_SUCCESS: {
      return { ...state, admins: action.data };
    }
    case CLEAR_PENDING_CLIENTS: {
      return {
        ...state,
        pendingClients: undefined,
      };
    }
    case PENDING_CLIENT_SEARCH_SUCCESS: {
      return {
        ...state,
        pendingClients: action.data,
      };
    }
    case GET_TEAM_MEMBERSHIP_SUCCESS:
    case UPDATE_TEAM_PAYMENT_PROFILE_SUCCESS:
    case UPDATE_MEMBERSHIP_SUCCESS:
    case CANCEL_PLAN_SUCCESS:
    case REACTIVATE_MEMBERSHIP_SUCCESS: {
      return {
        ...state,
        membership: action.data,
      };
    }
    case GET_DASHBOARD_SUCCESS: {
      return {
        ...state,
        dashboard: action.data,
      };
    }
    case GET_CLIENT_STATUS_AND_GOAL_DISTRIBUTION_SUCCESS: {
      return {
        ...state,
        ...action.data,
      };
    }
    case GET_ENGAGEMENT_SUMMARY_SUCCESS: {
      return {
        ...state,
        engagementSummary: action.data,
      };
    }
    case GET_ENGAGEMENT_SUMMARY_CSV_SUCCESS: {
      const { data: { users, total_days } } = action;
      const possibleGreenThumbs = total_days * 3;
      const exportHeaders = [
        { label: 'Name', key: 'name' },
        { label: 'Days Active', key: 'days_active' },
        { label: 'Days Logging', key: 'days_logging' },
        { label: 'Green Thumbs %', key: 'green_thumbs_pct' },
        { label: 'Carbs Hit %', key: 'carbs_hit_pct' },
        { label: 'Protein Hit %', key: 'protein_hit_pct' },
        { label: 'Fat Hit %', key: 'fat_hit_pct' },
        { label: 'Calories Hit %', key: 'calories_hit_pct' },
        { label: 'Weight Change', key: 'weight_change' },
        { label: 'Body Fat Change', key: 'body_fat_change' },
      ];
      const exportData = [];
      const numberFormat = '0,00.0';
      const percentFormat = '0';
      users.forEach((user) => {
        const {
          name,
          days_active,
          days_logging,
          green_thumbs,
          carbs_hit,
          protein_hit,
          fat_hit,
          calories_hit,
          weight_change,
          body_fat_change,
        } = user;

        const newUser = {
          name,
          days_active,
          days_logging,
          green_thumbs_pct: ((green_thumbs / possibleGreenThumbs) * 100).toFixed(),
          carbs_hit_pct: ((carbs_hit / total_days) * 100).toFixed(),
          protein_hit_pct: ((protein_hit / total_days) * 100).toFixed(),
          fat_hit_pct: ((fat_hit / total_days) * 100).toFixed(),
          calories_hit_pct: ((calories_hit / total_days) * 100).toFixed(),
          weight_change,
          body_fat_change,
        };
        exportData.push(newUser);
      });
      return {
        ...state,
        exportData,
        exportHeaders,
      };
    }
    case CLEAR_ENGAGEMENT_SUMMARY_CSV: {
      return {
        ...state,
        exportData: undefined,
        exportHeaders: undefined,
      };
    }
    case CLEAR_STREAM_TOKENS: {
      return {
        ...state,
        chatTokens: undefined,
      };
    }
    case PUT_STREAM_TOKEN: {
      const { tokens } = action;
      return {
        ...state,
        chatTokens: tokens,
      };
    }
    case STREAM_UNREAD_COUNT: {
      const { count } = action;
      const baseTitle = 'Macrostax Team';
      if (count !== undefined && count !== null && parseInt(count) > 0) {
        document.title = `(${count}) ${baseTitle}`;
      } else {
        document.title = baseTitle;
      }
      return {
        ...state,
        unreadChatCount: count,
      };
    }
    case GET_CUSTOM_FEATURES: {
      return {
        ...state,
        customFeatures: undefined,
      };
    }
    case GET_HIDDEN_FEATURES: {
      return {
        ...state,
        hiddenFeatures: undefined,
      };
    }
    case GET_CUSTOM_FEATURES_SUCCESS: {
      const customFeatures = action.data || [];
      return {
        ...state,
        customFeatures,
      };
    }
    case GET_HIDDEN_FEATURES_SUCCESS: {
      return {
        ...state,
        hiddenFeatures: action.data || [],
      };
    }
    case AUTH_LOGOUT:
    case SWITCH_TEAMS: {
      return {
        ...initialTeamState,
      };
    }
    case GET_GROUP_DETAILS_SUCCESS: {
      return {
        ...state,
        groupMembers: action.data.members,
      };
    }

    case DELETE_HIDDEN_FEATURE_SUCCESS: {
      return {
        ...state,
      };
    }

    case GET_CLIENTS_REDEEMED_SUCCESS: {
      return {
        ...state,
        clientsRedeemed: action.data
      };
    }

    case GET_INVITATION_TOKENS_SUCCESS: {
      return {
        ...state,
        invitationTokens: Array.isArray(action?.data) ? action.data : [],
        mainToken: action?.data?.[0] || null,
      };
    }

    default:
      return state;
  }
};
