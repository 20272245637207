import { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { acceptInvites, getClientsRedeemed, rejectInvites } from '../actions/teamActions';
import { submissionError } from '../actions/formActions';
import browserHistory from '../history';

const ALL_SELECTED = 'All';
const NONE_SELECTED = 'None';

const usePendingApprovalDeepLink = () => {
  const dispatch = useDispatch();
  const team = useSelector((state) => state.team);
  const { team_id, clientsRedeemed } = team;
  const [usersSelected, setUsersSelected] = useState([]);
  const [removeMembershipModalOpen, setRemoveMembershipModalOpen] = useState(false);
  const [select, setSelect] = useState(ALL_SELECTED);

  const _invitationTokenSelectedIds = useMemo(() => {
    return usersSelected.map((user) => user.id);
  }, [usersSelected]);

  const _validateClientsToRemove = (clientsToRemove) => {
    if (clientsToRemove.length === 0) {
      dispatch(submissionError({ clientsRedeemed: 'You must select at least one client to remove' }));
      return false;
    }

    return true;
  };

  const _clientsToRemove = (values) => {
    const clientsToRemove = [];
    for (let i = 0; i < clientsRedeemed.length; i += 1) {
      if (values[`user_${clientsRedeemed[i].id}`] !== undefined && values[`user_${clientsRedeemed[i].id}`] === true) {
        clientsToRemove.push(clientsRedeemed[i]);
      }
    }

    return clientsToRemove;
  };

  const isAllSelected = useMemo(() => select === ALL_SELECTED, [select]);

  const isNoneSelected = useMemo(() => select === NONE_SELECTED, [select]);

  const teamName = team && team.settings ? team.settings.name : team.name;

  const descriptionModal = usersSelected.length === 1
    ? `This client will no longer be able to sign up for or use the MacroStax app through ${teamName}.`
    : `These clients will no longer be able to sign up for or use the MacroStax app through ${teamName}.`;

  const selectAllOrNone = (form) => {
    for (let i = 0; i < clientsRedeemed.length; i += 1) {
      form.change(`user_${clientsRedeemed[i].id}`, isAllSelected);
    }

    setTimeout(() => {
      setSelect(isAllSelected ? NONE_SELECTED : ALL_SELECTED);
    });
  };

  const checkValues = (value, values, id, form) => {
    if (!value) {
      setSelect(ALL_SELECTED);
    } else {
      values[`user_${id}`] = true;
      let selected = 0;
      for (let i = 0; i < clientsRedeemed.length; i += 1) {
        if (values[`user_${clientsRedeemed[i].id}`]) {
          selected += 1;
        }
      }
      setSelect(selected === clientsRedeemed.length ? NONE_SELECTED : ALL_SELECTED);
    }
  };

  const removeClients = (values) => {
    const clientsToRemove = _clientsToRemove(values);

    if (!_validateClientsToRemove(clientsToRemove)) {
      return;
    }

    setUsersSelected(clientsToRemove);
    toggleRemoveMembershipModal();
  };

  const toggleRemoveMembershipModal = () => {
    setRemoveMembershipModalOpen(!removeMembershipModalOpen);
  };

  const onRowClick = (e, rowData) => {
    if (
      e.target instanceof HTMLInputElement ||
      e.target instanceof HTMLSpanElement
    ) {
      e.stopPropagation();
    } else {
      browserHistory.push(`/users/${rowData.id}?team_id=${team_id}`);
    }
  };

  const validateForm = (values) => {
    const errors = {};
    if (Object.keys(values).length === 0) {
      errors.user_0 = 'Please select at least one client';
    }
    return errors;
  };

  const onSubmit = async (values) => {
    const errors = validateForm(values);
    if (Object.keys(errors).length === 0) {
      await dispatch(acceptInvites(_invitationTokenSelectedIds));
      await dispatch(getClientsRedeemed());
    } else {
      dispatch(submissionError(errors));
    }
  };

  const onSearchBarChange = (value) => {
    if (value !== '') {
      const searchBar = document.querySelector('#mt-toolbar-container input');

      if (!searchBar) return;
      setTimeout(() => searchBar.focus());
    }
  };

  const onRejectClients = () => {
    dispatch(rejectInvites(_invitationTokenSelectedIds));
    toggleRemoveMembershipModal();
  };

  const onToggleCheckbox = (user, isChecked) => {
    if (isChecked) {
      setUsersSelected((prevUsersSelected) => [...prevUsersSelected, user]);
    } else {
      setUsersSelected((prevUsersSelected) => prevUsersSelected.filter((u) => u.id !== user.id));
    }
  };

  return {
    team,
    usersSelected,
    removeMembershipModalOpen,
    select,
    isAllSelected,
    isNoneSelected,
    selectAllOrNone,
    checkValues,
    removeClients,
    toggleRemoveMembershipModal,
    onRowClick,
    onSubmit,
    onRejectClients,
    onSearchBarChange,
    onToggleCheckbox,
    clientsRedeemed,
    descriptionModal,
  };
};

export default usePendingApprovalDeepLink;
