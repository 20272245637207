import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { submissionError } from '../actions/formActions';
import { editChatSettings } from '../actions/chatSettingActions';
import CustomToggleForm from './CustomToggleForm';

const ChatSettingsForm = ({
  initialValues,
  doEditChatSettings,
  isDisabled = true,
}) => (
  <CustomToggleForm
    initialValues={initialValues}
    doCustomToggle={doEditChatSettings}
    isDisabled={isDisabled}
    name="chat_coaches"
    label="Chat with Coaches"
  />
);

ChatSettingsForm.propTypes = {
  initialValues: PropTypes.object,
  doEditChatSettings: PropTypes.func,
  isDisabled: PropTypes.bool,
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  doEditChatSettings: (value) => dispatch(editChatSettings(value)),
  showSubmissionError: (errors, scroll) => dispatch(submissionError(errors, scroll)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatSettingsForm);
