import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader, Row, Col, Button, ModalFooter } from 'reactstrap';
import PageTitle from '../components/Common/PageTitle';
import UserAvatar from '../components/Common/UserAvatar';

const RemoveUsersModal = ({
  isOpen,
  toggle,
  users,
  title,
  description,
  page,
  confirmText,
  cancelText,
  onConfirm,
}) => {
  useEffect(() => {
    if (isOpen) {
      ReactGA.send({
        hitType: 'pageview',
        page,
        title,
      });
    }
  }, [isOpen]);

  const renderClients = () => users.map((u) => (
    <div className="user-row mt-1" key={u.id ? u.id : u.user_id}>
      <UserAvatar user={u} showName />
    </div>
  ));

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="modal-medium" onClosed={() => {}}>
      <ModalHeader toggle={toggle}>
        <PageTitle title={title} showTeam={false} icon="fa-1x far fa-lg mr-3 far fa fa-user-minus" />
        <Col xs={12}>
          <div>
            <p>
              {description}
            </p>
          </div>
        </Col>
      </ModalHeader>
      <ModalBody>
        <Col xs={12}>
          {renderClients()}
        </Col>
      </ModalBody>
      <ModalFooter>
        <Row className="mt-4">
          <Col xs={12}>
            <div className="d-flex justify-content-end">
              <Button
                color="secondary"
                size="lg"
                onClick={toggle}
                className="mr-3 fixed-width-md"
              >
                {cancelText}
              </Button>

              <Button
                color="primary"
                size="lg"
                onClick={onConfirm}
                className="fixed-width-md"
              >
                {confirmText}
              </Button>
            </div>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  );
};

RemoveUsersModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  users: PropTypes.instanceOf(Array),
  title: PropTypes.string,
  page: PropTypes.string,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  onConfirm: PropTypes.func,
};

export default RemoveUsersModal;
