import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { Row, Col, Button } from 'reactstrap';
import { OnChange } from 'react-final-form-listeners';
import MaterialTable, { MTableToolbar } from 'material-table';
import moment from 'moment';
import tableIcons from '../utils/muiIcons';
import renderCheckboxForField from './helpers/renderCheckboxForField';
import Pill from '../components/Common/Pill';
import {
  getClassNameForSubscriptionStatus,
  getFriendlyNameForPendingTokenClientStatus,
} from '../utils/subscriptionUtils';
import RemoveUsersModal from '../modals/RemoveUsersModal';
import usePendingApprovalDeepLink from '../presenters/usePendingApprovalDeepLink';

const Toolbar = ({ select, selectAllOrNone, form, values, ...props }) => (
  <div id="mt-toolbar-container">
    <MTableToolbar {...props} />
    <div className="ml-3">
      <span
        className="span-link"
        onClick={() => selectAllOrNone(form, values)}
      >
        Select {select}
      </span>
    </div>
  </div>
);

const ClientStatusCell = ({ rowData }) => (
  <Pill
    title={getFriendlyNameForPendingTokenClientStatus(rowData.status)}
    className={getClassNameForSubscriptionStatus(rowData.status === 'pending' ? 'active' : rowData.status)}
  />
);

const SelectCell = ({ rowData, checkValues, values, form, onToggleCheckbox }) => (
  <div className="d-flex align-items-center checkbox-row">
    <Field
      name={`user_${rowData.id}`}
      component={renderCheckboxForField}
      {...{
        itemProps: {},
        checkboxIcon: 'fa-check',
        checkboxIconUnchecked: 'fa-close',
        inputProps: {
          disabled: rowData.status !== 'pending',
        },
      }}
    />
    <OnChange name={`user_${rowData.id}`}>
      {(value) => {
        onToggleCheckbox(rowData, value);
        checkValues(value, values, rowData.id, form);
      }}
    </OnChange>
  </div>
);

const PendingApprovalDeepLinkForm = (props) => {
  const {
    pageSize = 25,
    showRemove = true,
    buttonText = 'Confirm Invite(s)',
    title = 'Clients Invited From Your Link',
  } = props;

  const {
    team,
    select,
    removeMembershipModalOpen,
    usersSelected,
    selectAllOrNone,
    checkValues,
    removeClients,
    toggleRemoveMembershipModal,
    onSubmit,
    onSearchBarChange,
    onRejectClients,
    onRowClick,
    onToggleCheckbox,
    clientsRedeemed,
    descriptionModal,
  } = usePendingApprovalDeepLink();

  return (
    <div>
      <Form
        onSubmit={onSubmit}
        initialValues={{}}
        keepDirtyOnReinitialize
        render={({ handleSubmit, form, values }) => (
          <form onSubmit={handleSubmit} className="inline-form">
            <Row className="mt-3">
              <Col xs={12}>
                <MaterialTable
                  columns={[
                    {
                      title: 'Select',
                      render: (rowData) => SelectCell({ rowData, checkValues, values, form, onToggleCheckbox }),
                      sorting: false,
                      width: null,
                      cellStyle: {
                        width: 100,
                        maxWidth: 100,
                      },
                    },
                    { title: 'Last Name', field: 'last_name', defaultSort: 'asc' },
                    { title: 'First Name', field: 'first_name' },
                    { title: 'Email', field: 'email' },
                    { title: 'Joined date', field: 'inserted_at', render: (rowData) => rowData.inserted_at ? moment(rowData.inserted_at).format('MM/DD/YYYY') : '' },
                    {
                      title: 'Client Status',
                      field: 'status',
                      render: (rowData) => ClientStatusCell({ rowData }),
                    },
                  ]}
                  data={clientsRedeemed.filter((client) => client.status === 'pending')}
                  title={title}
                  key={clientsRedeemed.length}
                  options={{
                    padding: 'dense',
                    pageSize,
                    search: true,
                    paging: clientsRedeemed.length > pageSize,
                    sorting: true,
                    thirdSortClick: false,
                    showTitle: true,
                    toolbar: true,
                    pageSizeOptions: [pageSize, 50, 100],
                    debounceInterval: 600,
                    draggable: false,
                  }}
                  style={{
                    marginTop: 20,
                    marginBottom: 20,
                    backgroundColor: '#f7f7f7',
                  }}
                  icons={tableIcons}
                  onSearchChange={onSearchBarChange}
                  onRowClick={onRowClick}
                  components={{
                    Toolbar: (props) => Toolbar({
                      select,
                      selectAllOrNone,
                      form,
                      values,
                      ...props,
                    }),
                  }}
                />
              </Col>
            </Row>

            <Row className="mt-3">
              <Col className="d-flex justify-content-end">
                {showRemove && (
                  <Button
                    color="secondary"
                    size="lg"
                    className="ml-3 fixed-width-xmd"
                    onClick={() => removeClients(values)}
                  >
                    Reject Invite(s)
                  </Button>
                )}
                <Button color="primary" size="lg" type="submit" className="ml-3 fixed-width-xmd">
                  {buttonText}
                </Button>
              </Col>
            </Row>

            <RemoveUsersModal
              isOpen={removeMembershipModalOpen}
              toggle={toggleRemoveMembershipModal}
              users={usersSelected}
              title="Reject Invite(s)"
              type="membership"
              team={team}
              confirmText="Reject invite(s)"
              cancelText="Cancel"
              description={descriptionModal}
              onConfirm={onRejectClients}
            />
          </form>
        )}
      />
    </div>
  );
};

PendingApprovalDeepLinkForm.propTypes = {
  pageSize: PropTypes.number,
  showRemove: PropTypes.bool,
  buttonText: PropTypes.string,
  title: PropTypes.string,
};

export default PendingApprovalDeepLinkForm;
