import React, { Component } from 'react';
import {
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
} from 'reactstrap';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import Select from 'react-select';
import scrollToComponent from 'react-scroll-to-component';
import Loading from '../../components/Common/Loading';
import DropdownSubMenu from '../../components/Common/DropdownSubMenu';
import Calendar from '../../components/Common/Calendar';
import UserAvatar from '../../components/Common/UserAvatar';
import Planner from '../../components/Planner/Planner';
import ChatUserModule from '../../components/Chat/ChatUserModule';
import LineChartCard from '../../components/Cards/LineChartCard';
import UserInfoForm from '../../forms/UserInfoForm';
import MacroTargetsForm from '../../forms/MacroTargetsForm';
import WeighInsForm from '../../forms/WeighInsForm';
import BodyFatsForm from '../../forms/BodyFatsForm';
import MeasurementsForm from '../../forms/MeasurementsForm';
import RemoveMembershipModal from '../../modals/RemoveMembershipModal';
import SetPasswordModal from '../../modals/SetPasswordModal';
import TeamTable from '../../components/Tables/TeamTable';
import { submissionError } from '../../actions/formActions';
import {
  updateUser,
  getPlan,
  getMembership,
  getTargets,
  syncIntercomContact,
  getDailyMacros,
  getAllUserMacros,
} from '../../actions/userActions';
import { clearStreamTokens, upsertStreamUsers } from '../../actions/authActions';
import { sendInvitation } from '../../actions/teamActions';
import {
  capitalizeFirst,
  formatPhone,
  formatHeightFromInches,
  getTimezoneLabel,
  formatWeightFromPounds,
} from '../../utils/stringUtils';
import {
  formatGoal,
  formatPhase,
  formatActivityLevel,
  formatDietaryPreferences,
  formatAdminRoles,
} from '../../utils/teamProfileUtils';
import { copyToClipboard } from '../../utils/global';
import {
  buildWeighInsLineChartData,
  buildMeasurementsLineChartData,
  buildBodyFatLineChartData,
} from '../../utils/chartUtils';
import { makeSimpleValue } from '../../utils/formUtils';
import { FORM_TRANSITION_PROPS, customSelectStyles } from '../../constants';
import { isTeamUser, userHasRole, isAuthorized } from '../../utils/authUtils';
import { Roles } from '../../enums/roles';
import browserHistory from '../../history';
import {
  getFriendlyNameForUserStatus,
  getClassNameForSubscriptionStatus,
} from '../../utils/subscriptionUtils';

const tz = moment.tz.guess();
const daySizeMultiplier = 0.888;
const teamConsumerRoleId = '9b23e53c-625d-4f45-8b4f-2387f456e80f';

class UserDetailContainer extends Component {
  constructor(props) {
    super(props);
    const { user } = this.props;
    let selected_team_id;
    if (user) {
      selected_team_id = user.selected_team_id;
    }
    this.state = {
      daySize: 40,
      activeTab: 'profile',
      timestamp: new Date().getTime(),
      removeMembershipModalOpen: false,
      selectedTeamId: selected_team_id,
    };
  }

  goToTeamDetail = (team) => {
    browserHistory.push(`/teams/${team.id}`);
  };

  toggleTab = (activeTab, selectedTeamId) => {
    const {
      doGetPlan,
      user,
      doGetMembership,
      doGetTargets,
      doGetDailyMacros,
      currentUser,
      doClearStreamTokens,
      doUpsertStreamUsers,
    } = this.props;
    this.setState({
      activeTab,
      timestamp: new Date().getTime(),
    });
    if (activeTab === 'planner' && !user.plan) {
      doGetPlan(user.user_id, moment().format('YYYY-MM-DD'));
    }
    if (activeTab === 'billing' && !user.membership) {
      doGetMembership(user.user_id);
    }
    if (activeTab === 'nutrition') {
      const start_date = moment().startOf('month').add(-1, 'M').format('YYYY-MM-DD');
      const end_date = moment().endOf('month').add(1, 'M').format('YYYY-MM-DD');
      // doGetTargets(user.user_id, selectedTeamId);
      doGetDailyMacros(user.user_id, start_date, end_date);
    }
    if (activeTab === 'chat' && currentUser.user_id !== user.user_id) {
      doClearStreamTokens();
      const users = {};
      users[user.user_id] = {
        id: user.user_id,
        name: `${user.first_name} ${user.last_name}`,
      };
      users[currentUser.user_id] = {
        id: currentUser.user_id,
        name: `${currentUser.first_name} ${currentUser.last_name}`,
      };
      doUpsertStreamUsers(users);
    }
    setTimeout(() =>
      this.setState({ daySize: Math.floor((this.container.offsetWidth * daySizeMultiplier) / 7) })
    );
  };

  toggleForm = (form) => {
    const prevState = this.state[`show${form}`]; // eslint-disable-line
    this.setState({
      [`show${form}`]: !prevState,
    });
    setTimeout(() => {
      const component = prevState ? this.UserInfoContainer : this[form];
      if (component) {
        scrollToComponent(component, { duration: 250, offset: -20, align: 'top' });
      }
    }, 300);
  };

  getTeamStatus = (teams, teamId) => {
    return getFriendlyNameForUserStatus(
      teams.find((t) => t.id === teamId && t.role_id === teamConsumerRoleId).status
    );
  };

  getActiveTeamId = (teams, selected_team_id) => {
    if (selected_team_id && selected_team_id.length > 0) {
      return selected_team_id;
    }
    const activeTeams = teams.filter((t) =>
      ['active', 'pre-active', 'onboarding'].includes(t.status)
    );
    if (activeTeams.length === 1) {
      return activeTeams[0].id;
    } if (activeTeams.length > 1) {
      // get first team with a consumer role
      const firstTeam = activeTeams.find(
        // (t) => t.role_id === teamConsumerRoleId && t.id !== ENV_VARS.MACROSTAX_TEAM_ID
        (t) => t.role_id === teamConsumerRoleId && !t.is_personal
      );
      if (firstTeam) {
        return firstTeam.id;
      }
      return activeTeams[0].id;
    }
    if (teams.length) {
      return teams[0].id;
    }
    return undefined;
  };

  toggleRemoveMembershipModal = () => {
    const { removeMembershipModalOpen } = this.state;
    this.setState({
      removeMembershipModalOpen: !removeMembershipModalOpen,
    });
  };

  toggleSetPasswordModal = () => {
    const { setPasswordModalOpen } = this.state;
    this.setState({
      setPasswordModalOpen: !setPasswordModalOpen,
    });
  };

  render() {
    const {
      user,
      showSubmissionError,
      doUpdateUser,
      currentUser,
      team,
      doSendInvitation,
      doGetPlan,
      doSyncIntercomContact,
      doGetTargets,
      doGetDailyMacros,
      doGetAllUserMacros,
    } = this.props;

    const {
      active_campaign_id,
      // activity = [],
      activity_level_id,
      age,
      birthday,
      created_at,
      dietary_preferences = [],
      email,
      first_name,
      gender,
      goal_id,
      goal_weight,
      // green_thumbs,
      height,
      roles = [],
      // is_custom_macros,
      last_name,
      // logins,
      measurements = [],
      membership,
      phase_id,
      phone,
      preferred_food_units,
      preferred_weight_units,
      plan,
      status,
      timezone = 'America/Denver',
      user_id,
      weigh_ins = [],
      body_fats = [],
      weight,
    } = user;
    let { last_login, last_logged_meal } = user;
    const { teams = [], selected_team_id } = user;
    if (last_login && last_login.indexOf('Z') === -1) {
      last_login += 'Z';
    }
    if (last_logged_meal && last_logged_meal.indexOf('Z') === -1) {
      last_logged_meal += 'Z';
    }
    const teamsForGrid = teams.filter((t) => !t.is_personal);
    const current_user_roles = currentUser.roles;
    const teamAdmin = isTeamUser(current_user_roles);
    const {
      showUserInfoForm,
      daySize,
      activeTab,
      timestamp,
      removeMembershipModalOpen,
      setPasswordModalOpen,
      selectedTeamId,
    } = this.state;
    const weighInsChart = buildWeighInsLineChartData(weigh_ins, preferred_weight_units);
    if (user && weighInsChart) {
      weighInsChart.id = `${user_id}_weighins`;
      weighInsChart.title = `${first_name} ${last_name} Weigh-Ins`;
    }
    const bodyFatChart = buildBodyFatLineChartData(body_fats);
    if (user && bodyFatChart) {
      bodyFatChart.id = `${user_id}_bodyfats`;
      bodyFatChart.title = `${first_name} ${last_name} Body Fat`;
    }
    const measurementsChart = buildMeasurementsLineChartData(measurements, preferred_weight_units);
    if (user && measurementsChart) {
      measurementsChart.id = `${user_id}_measurements`;
      measurementsChart.title = `${first_name} ${last_name} Measurements`;
    }
    const dropdown = [
      { key: 'last_week', value: 'Last week' },
      { key: 'last_30_days', value: 'Last 30 days' },
      { key: 'all_time', value: 'All time' },
    ];

    const tabButtons = [
      {
        label: 'Profile',
        icon: 'icon-user',
        onClick: () => this.toggleTab('profile'),
        roles: [Roles.INTERNAL_ADMIN, Roles.TEAM_ADMIN, Roles.TEAM_OWNER],
      },
      {
        label: 'Nutrition',
        icon: 'ms-icon- icon-Knife_-Fork_-Restaurant_-Food',
        onClick: () => this.toggleTab('nutrition', selected_team_id),
        roles: [Roles.INTERNAL_ADMIN, Roles.TEAM_ADMIN, Roles.TEAM_OWNER],
      },
      {
        label: 'Progress',
        icon: 'fas fa-chart-line',
        onClick: () => this.toggleTab('progress'),
        roles: [Roles.INTERNAL_ADMIN, Roles.TEAM_ADMIN, Roles.TEAM_OWNER],
      },
      {
        label: 'Planner',
        icon: 'fas fa-list',
        onClick: () => this.toggleTab('planner'),
        roles: [Roles.INTERNAL_ADMIN, Roles.TEAM_ADMIN, Roles.TEAM_OWNER],
      },
      {
        label: 'Chat',
        icon: 'far fa-comment',
        onClick: () => this.toggleTab('chat'),
        roles: [Roles.TEAM_ADMIN, Roles.TEAM_OWNER],
      },
      {
        label: 'Team',
        icon: 'ms-icon- icon-dumbbell',
        onClick: () => this.toggleTab('team'),
        roles: [Roles.INTERNAL_ADMIN],
      },
      {
        label: 'Billing',
        icon: 'far fa-credit-card',
        onClick: () => this.toggleTab('billing'),
        roles: [Roles.INTERNAL_ADMIN],
      },
      /*
      {
        label: 'Activity',
        icon: 'icon-notebook',
        onClick: () => this.toggleTab('activity'),
        roles: [Roles.INTERNAL_ADMIN],
      },
      */
    ];
    const authorizedTabButtons = [];
    for (let i = 0; i < tabButtons.length; i += 1) {
      const button = tabButtons[i];
      if (isAuthorized(current_user_roles, button)) {
        authorizedTabButtons.push(button);
      }
    }
    const actionMenuButtons = [];
    let contextTeamId;
    if (teamAdmin) {
      let status;
      if (team && team.settings && teams && teams.length) {
        status = this.getTeamStatus(teams, team.settings.team_id).toLowerCase();
        contextTeamId = team.settings.team_id;
      }
      if (status !== 'active') {
        actionMenuButtons.push({
          icon: 'fa-lg mr-2 far fa-envelope',
          label: 'Send Invitation to Join Team',
          onClick: () => doSendInvitation(user),
        });
      }
      if (status !== 'revoked' && status !== 'deactivated') {
        actionMenuButtons.push({
          icon: 'fa-lg mr-2 far fa-trash-alt',
          label: 'Remove Membership',
          onClick: () => this.toggleRemoveMembershipModal(),
        });
      }
    } else {
      contextTeamId = this.getActiveTeamId(teams, selected_team_id);
      actionMenuButtons.push({
        icon: 'fa-lg mr-2 fa fa-unlock-alt',
        label: 'Reset Password',
        onClick: () => this.toggleSetPasswordModal(),
      });
      if (active_campaign_id) {
        actionMenuButtons.push({
          icon: 'fa-lg mr-2 ms-icon- icon-active-campaign',
          label: 'View on ActiveCampaign',
          onClick: () =>
            window.open(`https://macrostax.activehosted.com/app/contacts/${active_campaign_id}`),
        });
      }
      actionMenuButtons.push({
        icon: 'fa-lg mr-2 fa fa-sync',
        label: 'Sync Intercom Contact',
        onClick: () => doSyncIntercomContact(user_id, contextTeamId),
      });
      actionMenuButtons.push({
        icon: 'fa-lg mr-2 ms-icon- icon-intercom',
        label: 'View on Intercom',
        onClick: () =>
          window.open(
            `https://app.intercom.com/a/apps/sisozev5/users/segments/all-users?searchTerm=${email}`
          ),
      });
    }

    const consumerTeams = teams.filter((t) => t.role_id === teamConsumerRoleId);
    const consumerTeamOptions = consumerTeams.map((t) => ({ value: t.team_id, label: t.name }));
    const isCustomMacros =
      user && user.targets && user.targets.custom && user.targets.custom.length > 0;
    user.isCustomMacros = isCustomMacros;

    return (
      <>
        {first_name && team && team.profile ? (
          <>
            <Row>
              <Col md={9}>
                <div
                  className="d-flex flex-row align-items-center"
                  ref={(div) => {
                    this.UserInfoContainer = div;
                  }}
                >
                  <UserAvatar user={user} />
                  <div className="ml-3">
                    <div className="user-name">{`${first_name} ${last_name}`}</div>
                    <div className="d-flex flex-wrap">
                      {isCustomMacros && (
                        <span className="alert alert-warning mr-3">Client is on custom macros</span>
                      )}
                      {userHasRole(user, Roles.INTERNAL_ADMIN, roles) && (
                        <span className="alert alert-warning mr-3">Admin</span>
                      )}
                      {userHasRole(user, Roles.INTERNAL_RECIPE_ADMIN, roles) && (
                        <span className="alert alert-warning mr-3">Recipe Admin</span>
                      )}
                    </div>
                  </div>
                </div>
              </Col>
              {/* {teamAdmin ? (
                <Col className="text-align-right d-flex justify-content-end">
                  <div className="d-flex">
                    <SendEmailButton />
                    <RemoveMembershipButton className="ml-3" user={user} />
                  </div>
                </Col>
              ) : ( */}
              <Col className="d-flex justify-content-end align-items-center">
                <div className="ml-2">
                  <DropdownSubMenu
                    buttons={actionMenuButtons}
                    title="Quick Actions"
                    persist={false}
                  />
                </div>
              </Col>
              {/* )} */}
            </Row>
            <Row className="mt-4">
              <Col xs={12}>
                <div className="d-md-none">
                  <DropdownSubMenu
                    buttons={authorizedTabButtons}
                    title={`${activeTab.charAt(0).toUpperCase()}${activeTab.substring(1)}`}
                  />
                </div>
                <Nav tabs className="d-none d-md-flex">
                  {authorizedTabButtons.map((t) => (
                    <NavItem
                      key={t.label}
                      className={activeTab === t.label.toLowerCase() ? 'active' : ''}
                    >
                      <NavLink onClick={t.onClick}>
                        {t.icon && <em className={t.icon} />}
                        {t.label}
                      </NavLink>
                      <div className="tab-indicator animated" />
                    </NavItem>
                  ))}
                </Nav>
              </Col>
            </Row>
            <TabContent activeTab={activeTab} className="animated">
              <TabPane tabId="profile">
                <Row>
                  <Col sm="6">
                    <div
                      className="mb-4 cursor-pointer"
                      onClick={() => this.toggleForm('UserInfoForm')}
                    >
                      <h4 className={showUserInfoForm ? 'cancelable' : 'editable'}>
                        Personal Info
                      </h4>
                    </div>
                  </Col>
                </Row>
                <CSSTransition
                  {...FORM_TRANSITION_PROPS}
                  in={showUserInfoForm}
                  onExited={() => {
                    this.setState({
                      showUserInfoForm: false,
                    });
                  }}
                >
                  <Row>
                    <Col md={12}>
                      <div
                        ref={(div) => {
                          this.UserInfoForm = div;
                        }}
                      >
                        <UserInfoForm
                          initialValues={{
                            first_name,
                            last_name,
                            email,
                            phone,
                            gender,
                            birthday: birthday ? moment(birthday).toDate() : '',
                            created_at,
                            height,
                            weight,
                            goal_weight,
                            goal_id,
                            phase_id,
                            activity_level_id,
                            dietary_preferences,
                            preferred_weight_units,
                            preferred_food_units,
                            timezone,
                            roles,
                            team_id: contextTeamId,
                          }}
                          showSubmissionError={showSubmissionError}
                          onSubmit={(values) => {
                            this.props.doUpdateUser(user_id, values);
                            this.toggleForm('UserInfoForm');
                          }}
                          teamAdmin={teamAdmin}
                          teamProfile={team.profile}
                          consumerTeams={consumerTeams}
                        />
                      </div>
                    </Col>
                  </Row>
                </CSSTransition>
                {!showUserInfoForm && (
                  <>
                    <Row>
                      <Col lg={4} md={6}>
                        <div className="team-data-section">
                          {!teamAdmin && (
                            <div
                              className="team-row cursor-pointer"
                              onClick={() => {
                                copyToClipboard(user_id, 'User ID');
                              }}
                            >
                              <label>User ID</label>
                              <div className="copyable">{user_id}</div>
                            </div>
                          )}
                          <div className="team-row">
                            <label>Gender</label>
                            <div>{gender ? capitalizeFirst(gender) : '--'}</div>
                          </div>
                          <div className="team-row">
                            <label>Age</label>
                            <div>{age ? `${age} years old` : '--'}</div>
                          </div>
                          <div className="team-row">
                            <label>Join Date</label>
                            <div>
                              {created_at ? moment(created_at).tz(tz).format('MMMM D, YYYY') : '--'}
                            </div>
                          </div>
                          <div className="team-row">
                            <label>{teamAdmin && <>Client</>} Status</label>
                            {teamAdmin && team && team.settings ? (
                              <div>
                                {capitalizeFirst(this.getTeamStatus(teams, team.settings.team_id))}
                              </div>
                            ) : (
                              <div>{status ? capitalizeFirst(status) : '--'}</div>
                            )}
                          </div>
                          <div className="team-row">
                            <label>Birthday</label>
                            <div>
                              {birthday && timezone
                                ? // ? moment(birthday).tz(timezone).format('MMMM D, YYYY')
                                  moment(birthday).format('MMMM D, YYYY')
                                : '--'}
                            </div>
                          </div>
                          <div className="team-row">
                            <label>Last Logged Meal</label>
                            <div>
                              {last_logged_meal && timezone
                                ? moment(last_logged_meal).tz(timezone).format('M/D/YYYY h:mm a z')
                                : '--'}
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col lg={4} md={6}>
                        <div className="team-data-section">
                          <div className="team-row">
                            <label>Weight Units</label>
                            <div>{preferred_weight_units || '--'}</div>
                          </div>
                          <div className="team-row">
                            <label>Food Units</label>
                            <div>{preferred_food_units || '--'}</div>
                          </div>
                          <div
                            className="team-row cursor-pointer"
                            onClick={() => {
                              copyToClipboard(email, 'Email Address');
                            }}
                          >
                            <label>Email Address</label>
                            <div className="copyable">{email && email}</div>
                          </div>
                          <div className="team-row">
                            <label>Phone</label>
                            <div>{phone ? formatPhone(phone) : '--'}</div>
                          </div>
                          <div className="team-row">
                            <label>Timezone</label>
                            <div>{timezone ? getTimezoneLabel(timezone) : '--'}</div>
                          </div>
                          <div className="team-row">
                            <label>Height</label>
                            <div>{height ? formatHeightFromInches(height, preferred_weight_units) : '--'}</div>
                          </div>
                          <div className="team-row">
                            <label>Weight</label>
                            <div>{weight ? formatWeightFromPounds(weight, preferred_weight_units) : '--'}</div>
                          </div>
                          <div className="team-row">
                            <label>Goal Weight</label>
                            <div>{goal_weight ? formatWeightFromPounds(goal_weight, preferred_weight_units) : '--'}</div>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="team-data-section">
                          <div className="team-row">
                            <label>Goal/Phase</label>
                            <div>
                              {consumerTeams.length > 1 && !teamAdmin ? (
                                <>
                                  (See{' '}
                                  <span
                                    className="span-link"
                                    onClick={() => this.toggleTab('nutrition', selected_team_id)}
                                  >
                                    Nutrition
                                  </span>{' '}
                                  tab above)
                                </>
                              ) : goal_id && phase_id ? (
                                `${formatGoal(team.profile, goal_id)} / ${formatPhase(
                                  team.profile,
                                  phase_id
                                )}`
                              ) : (
                                '--'
                              )}
                            </div>
                          </div>
                          <div className="team-row">
                            <label>Activity Level</label>
                            <div>
                              {consumerTeams.length > 1 && !teamAdmin ? (
                                <>
                                  (See{' '}
                                  <span
                                    className="span-link"
                                    onClick={() => this.toggleTab('nutrition', selected_team_id)}
                                  >
                                    Nutrition
                                  </span>{' '}
                                  tab above)
                                </>
                              ) : activity_level_id ? (
                                capitalizeFirst(
                                  formatActivityLevel(team.profile, activity_level_id)
                                )
                              ) : (
                                '--'
                              )}
                            </div>
                          </div>
                          <div className="team-row">
                            <label>Dietary Preferences</label>
                            <div>
                              {dietary_preferences && dietary_preferences.length
                                ? formatDietaryPreferences(team.profile, dietary_preferences).join(
                                    ', '
                                  )
                                : 'None'}
                            </div>
                          </div>
                          <div className="team-row">
                            <label>Last Login</label>
                            <div>
                              {last_login && timezone
                                ? moment(last_login).tz(timezone).format('M/D/YYYY h:mm a z')
                                : '--'}
                            </div>{' '}
                          </div>
                          {!teamAdmin && (
                            <div
                              className="team-row cursor-pointer"
                              onClick={() => {
                                active_campaign_id
                                  ? copyToClipboard(active_campaign_id, 'Active Campaign ID')
                                  : void 1;
                              }}
                            >
                              <label>Active Campaign ID</label>
                              <div className="copyable">
                                {active_campaign_id || '--'}
                              </div>
                            </div>
                          )}
                          {!teamAdmin && (
                            <div className="team-row">
                              <label>Internal Admin Roles</label>
                              <div>
                                {roles && roles.length
                                  ? formatAdminRoles(team.profile, roles).join(', ')
                                  : 'None'}
                              </div>
                            </div>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
              </TabPane>
              <TabPane tabId="nutrition">
                <Row>
                  <Col xl={5}>
                    <div ref={(el) => (this.container = el)}>
                      {user.dailyMacros ? (
                        <Calendar
                          daySize={daySize}
                          timestamp={timestamp}
                          render={activeTab === 'nutrition'}
                          dailyMacros={user.dailyMacros}
                          container={this.container}
                          getDailyMacros={doGetDailyMacros}
                          getPlan={doGetPlan}
                          user_id={user_id}
                          switchTab={() => this.toggleTab('planner')}
                        />
                      ) : (
                        <Loading />
                      )}
                    </div>
                  </Col>

                  <Col xl={7}>
                    {consumerTeams.length > 1 && !teamAdmin && (
                      <>
                        <FormGroup className="stacked">
                          Select a Team to view:
                          <div className="">
                            <Select
                              styles={customSelectStyles}
                              defaultValue={consumerTeamOptions.find(
                                (t) => t.value === selectedTeamId
                              )}
                              isClearable={false}
                              options={consumerTeamOptions}
                              onChange={(value) => {
                                user.selected_team_id = value.value;
                                this.setState(
                                  {
                                    selectedTeamId: value.value,
                                  },
                                  () => doGetAllUserMacros(user_id, value.value)
                                );
                              }}
                            />
                          </div>
                        </FormGroup>
                      </>
                    )}
                    {/* {!teamAdmin && (
                      <MacroTargetsTable
                        user={user}
                        render={activeTab === 'nutrition'}
                        showGoal={true}
                        teamProfile={team.profile}
                        showEditGoal={consumerTeams.length > 1 && !teamAdmin}
                        teamName={
                          consumerTeams.length > 1 && !teamAdmin
                            ? consumerTeamOptions.find((t) => t.value === selectedTeamId).label
                            : ''
                        }
                        team_id={selectedTeamId}
                      />
                    )} */}

                    {user &&
                      user.targets &&
                      user.targets &&
                      user.targets.macrostax &&
                      user.targets.macrostax.length && (
                        <MacroTargetsForm
                          user={user}
                          timestamp={timestamp}
                          render={activeTab === 'nutrition'}
                          team_id={contextTeamId}
                          showEditGoal={consumerTeams.length > 1 && !teamAdmin}
                          teamProfile={team.profile}
                          teamName={
                            consumerTeams.length > 1 && !teamAdmin
                              ? consumerTeamOptions.find((t) => t.value === selectedTeamId).label
                              : ''
                          }
                        />
                      )}
                    {user && user.targets && !user.targets.macrostax && (
                      <Card className="card-default card-container mb-3 line-chart-card flex-grow-1">
                        <CardHeader className="d-flex justify-space-between align-items-center" />
                        <CardBody>
                          <div className="no-results-container text-center mb-4">
                            <h3>No macro targets yet.</h3>
                            <div className="mb-3">
                              This client has not had macro targets calculated yet.
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    )}
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="progress">
                <Row>
                  {/*
              <Col md={4}>
                <StatisticSelectCard header="Logins" dropdown={dropdown} data={logins} />
                <StatisticSelectCard
                  header="Days w/ 1+ Green Thumb"
                  dropdown={dropdown}
                  data={green_thumbs}
                />
              </Col>
              */}
                  <Col>
                    <LineChartCard
                      showEdit={true}
                      // showEdit={!teamAdmin}
                      title="Weight"
                      chart={weighInsChart}
                      data={weigh_ins}
                      noResultsNoun="weigh ins"
                      form={<WeighInsForm user={user} />}
                      render={activeTab === 'progress'}
                      showPdf={true}
                    />
                    <LineChartCard
                      showEdit={true}
                      // showEdit={!teamAdmin}
                      title="Body Fat"
                      chart={bodyFatChart}
                      data={body_fats}
                      noResultsNoun="body fat measurements"
                      form={<BodyFatsForm user={user} />}
                      render={activeTab === 'progress'}
                      showPdf={true}
                    />
                    <LineChartCard
                      showEdit={true}
                      // showEdit={!teamAdmin}
                      title="Measurements"
                      chart={measurementsChart}
                      noResultsNoun="measurements"
                      form={<MeasurementsForm user={user} />}
                      render={activeTab === 'progress'}
                      showPdf={true}
                    />
                  </Col>
                </Row>
              </TabPane>
              {!teamAdmin && (
                <>
                  <TabPane tabId="team">
                    <Row>
                      <Col md={12}>
                        {teamsForGrid.length ? (
                          <TeamTable
                            teams={teamsForGrid}
                            title={`${first_name}'${first_name.charAt !== 's' ? 's' : ''} Team`}
                            onRowClick={(e, rowData) => this.goToTeamDetail(rowData)}
                            showClients={false}
                            showRole={true}
                            showJoinDate={false}
                            showStatusTooltip={false}
                          />
                        ) : (
                          <>
                            <h4>Team</h4>
                            <div>{first_name} is not a client of any teams</div>
                          </>
                        )}
                      </Col>
                    </Row>
                  </TabPane>

                  <TabPane tabId="billing">
                    <Row>
                      <Col md={12}>
                        {membership && membership === 'NOT_FOUND' ? (
                          <div>We could not find an active membership for this user.</div>
                        ) : membership ? (
                          <Row className="table-statistics mt-3">
                            <Col md={3}>
                              <div className="table-header">Processor</div>
                              <div className="statistic">
                                {capitalizeFirst(membership.payment_gateway)}
                              </div>
                              {membership.payment_gateway === 'stripe' && (
                                <div className="card-caption">
                                  <a
                                    href={`https://dashboard.stripe.com/customers/${membership.external_user_id}`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {membership.external_user_id}
                                  </a>
                                </div>
                              )}
                            </Col>
                            <Col md={3}>
                              <div className="table-header">Plan</div>
                              <div className="statistic">{membership.product_name}</div>
                              {membership.payment_gateway === 'stripe' && (
                                <div className="card-caption">
                                  <a
                                    href={`https://dashboard.stripe.com/subscriptions/${membership.external_membership_id}`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {membership.external_membership_id}
                                  </a>
                                </div>
                              )}
                              <div className="mt-1 card-caption">
                                Status:{' '}
                                <span
                                  className={getClassNameForSubscriptionStatus(membership.status)}
                                >
                                  {capitalizeFirst(
                                    membership.status.replace('cancellation', 'cancelation')
                                  )}
                                </span>
                              </div>
                            </Col>
                            {membership.payment_gateway === 'stripe' && (
                              <>
                                {membership.status !== 'canceled' &&
                                  membership.status !== 'pending-cancellation' && (
                                    <Col md={3}>
                                      <div className="table-header">Next Bill Amount</div>
                                      <div className="statistic">
                                        ${membership.next_bill_amount}
                                      </div>
                                    </Col>
                                  )}
                                {membership.status !== 'canceled' && (
                                  <Col md={3}>
                                    <div className="table-header mobile-mt-1">
                                      {membership.status === 'pending-cancellation'
                                        ? 'Cancelation '
                                        : 'Renewal '}{' '}
                                      Date
                                    </div>
                                    <div className="statistic">
                                      {moment(membership.next_bill_date)
                                        .tz(timezone)
                                        .format('M/D/YYYY')}
                                    </div>
                                  </Col>
                                )}
                              </>
                            )}
                          </Row>
                        ) : (
                          <Loading />
                        )}
                      </Col>
                    </Row>
                  </TabPane>
                </>
              )}
              {!teamAdmin && (
                <TabPane tabId="activity">
                  <Row />
                </TabPane>
              )}
              <TabPane tabId="planner">
                <Row>
                  <Col md={12}>
                    {plan && plan.day_type !== undefined && plan.day_type.length ? (
                      <Planner plan={plan} doGetPlan={doGetPlan} user={user} />
                    ) : plan && plan.day_type !== undefined && !plan.day_type.length ? (
                      <>There was a problem loading this client's planner</>
                    ) : plan && plan.missing_macros !== undefined ? (
                      <div>This client does not have any macro targets yet.</div>
                    ) : (
                      <Loading />
                    )}
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="chat">
                <Row>
                  <Col md={12}>
                    {user.user_id === currentUser.user_id ? (
                      <div>
                        Hello, {currentUser.first_name}! You are not currently able to initiate a
                        chat with yourself. If you are looking to test this feature, we recommend
                        inviting a staff member and initiating a chat with them. Need help? Please
                        reach out to our <span className="span-link intercom">support team</span>!
                      </div>
                    ) : team && team.chatTokens !== undefined ? (
                      <ChatUserModule
                        targetUser={user}
                        currentUser={currentUser}
                      />
                    ) : (
                      <Loading />
                    )}
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
            {team && team.settings && team.settings.team_id && (
              <RemoveMembershipModal
                users={[user]}
                isOpen={removeMembershipModalOpen}
                toggle={this.toggleRemoveMembershipModal}
                team={team}
                isPending={
                  teamAdmin &&
                  ['pending', 'invited'].includes(this.getTeamStatus(teams, team.settings.team_id))
                }
              />
            )}

            <SetPasswordModal
              user={user}
              isOpen={setPasswordModalOpen}
              toggle={this.toggleSetPasswordModal}
            />
          </>
        ) : (
          <Loading />
        )}
      </>
    );
  }
}

UserDetailContainer.propTypes = {
  user: PropTypes.instanceOf(Object),
  showSubmissionError: PropTypes.func,
  doUpdateUser: PropTypes.func,
  currentUser: PropTypes.instanceOf(Object),
  team: PropTypes.instanceOf(Object),
  doSendInvitation: PropTypes.func,
  doGetPlan: PropTypes.func,
  doGetMembership: PropTypes.func,
  doGetTargets: PropTypes.func,
  doSyncIntercomContact: PropTypes.func,
  doGetAllUserMacros: PropTypes.func,
  doClearStreamTokens: PropTypes.func,
  doUpsertStreamUsers: PropTypes.func,
};

const mapStateToProps = (state) => ({
  user: state.user,
  currentUser: state.auth.user,
  team: state.team,
});
const mapDispatchToProps = (dispatch) => ({
  showSubmissionError: (errors) => dispatch(submissionError(errors)),
  doUpdateUser: (id, values) => {
    values.goal_id = makeSimpleValue(values.goal_id);
    values.gender = makeSimpleValue(values.gender);
    values.phase_id = makeSimpleValue(values.phase_id);
    values.activity_level_id = makeSimpleValue(values.activity_level_id);
    values.timezone = makeSimpleValue(values.timezone);
    if (values.dietary_preferences && values.dietary_preferences.length) {
      if (typeof values.dietary_preferences[0] === 'object') {
        values.dietary_preferences = values.dietary_preferences.map((v) => v.value);
      }
    }
    if (values.roles && values.roles.length) {
      if (typeof values.roles[0] === 'object') {
        values.roles = values.roles.map((v) => v.value);
      }
    }
    values.goal_weight = values.goal_weight || '';
    values.phone = values.phone || '';
    dispatch(updateUser(id, values));
  },
  doSendInvitation: (user) => dispatch(sendInvitation(user)),
  doGetPlan: (user_id, date, switchTab) => dispatch(getPlan(user_id, date, switchTab)),
  doGetMembership: (user_id) => dispatch(getMembership(user_id)),
  doGetTargets: (user_id, team_id) => dispatch(getTargets(user_id, team_id)),
  doSyncIntercomContact: (user_id, team_id) => dispatch(syncIntercomContact(user_id, team_id)),
  doGetDailyMacros: (user_id, start_date, end_date) =>
    dispatch(getDailyMacros(user_id, start_date, end_date)),
  doGetAllUserMacros: (user_id, team_id) => dispatch(getAllUserMacros(user_id, team_id)),
  doClearStreamTokens: () => dispatch(clearStreamTokens()),
  doUpsertStreamUsers: (users) => dispatch(upsertStreamUsers(users)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserDetailContainer);
