import React, { useEffect, useState } from 'react';
import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import DropdownSubMenu from '../../components/Common/DropdownSubMenu';
import PageTitle from '../../components/Common/PageTitle';
import Loading from '../../components/Common/Loading';
import SampleClientProfileModal from '../../modals/SampleClientProfileModal';
import AddClientForm from '../../forms/AddClientForm';
import AddClientsFromCsvForm from '../../forms/AddClientsFromCsvForm';
import PendingClientsForm from '../../forms/PendingClientsForm';
import { submissionError } from '../../actions/formActions';
import {
  addClients,
  addHiddenFeature,
  clearClientsSearchForm,
  clearPendingClients,
  deleteHiddenFeature,
  getClientsRedeemed,
  getHiddenFeatures,
  getInvitationTokens,
  getPendingClients,
  sendInvitations,
  submitPendingClientsSearchRequest,
} from '../../actions/teamActions';
import CSVUploader from '../../components/Common/CSVUploader.tsx';
import browserHistory from '../../history';
import ManageInvitesSearchForm from '../../forms/ManageInvitesSearchForm';
import SideMenu from '../../components/GroupSideMenu/GroupSideMenu';
import CustomToggleForm from '../../forms/CustomToggleForm';
import { shareTeamLink } from '../../utils/deepLink';
import PendingApprovalDeepLinkForm from '../../forms/PendingApprovalDeepLinkForm';
import { copyToClipboard } from '../../utils/global';

const BUTTONS = [
  {
    title: 'Upload Individually',
    parentId: 'addclients',
    id: 'individually',
  },
  {
    title: 'Upload CSV File',
    parentId: 'addclients',
    id: 'csv',
  },
  {
    title: 'Link to Join My Team',
    parentId: 'addclients',
    id: 'deeplink',
  },
];

const AddClients = ({
  team,
  showSubmissionError,
  doAddClients,
  user,
  searchPendingClients,
  resetSearchPendingClients,
  doGetHiddenFeatures,
  hiddenFeatures,
  initialDeepLinkValues,
  doDeleteHiddenFeature,
  doAddHiddenFeature,
  doSendInvitations,
  pendingClients,
  doGetPendingClients,
  doClearPendingClients,
  clientsRedeemed,
  doGetClientsRedeemed,
  doGetInvitationTokens,
  mainToken,
}) => {
  const [activeTab, setActiveTab] = useState('addclients');
  const [activeSub, setActiveSub] = useState('individually');
  const [sampleModalOpen, setSampleModalOpen] = useState(false);
  const [results, setResults] = useState([]);
  const [csvInitialValues, setCsvInitialValues] = useState({});
  const [initialValues] = useState({
    send_invitations: true,
  });
  const tabButtons = [
    {
      label: 'Add Clients',
      id: 'addclients',
      icon: 'fa fa-user-plus',
      onClick: () => toggleTab('addclients'),
    },
    {
      label: 'Manage Invites',
      id: 'invites',
      icon: 'fa fa-user-clock',
      onClick: () => {
        toggleTab('invites');
      },
    },
  ];

  const toggleTab = (activeTab, doPush = true) => {
    setActiveTab(activeTab);
    if (activeTab === 'invites') {
      refreshPendingClients();
    }

    if (doPush) {
      window.history.pushState('', '', `/addclients#${activeTab}`);
    }
  };

  const toggleSampleModal = () => {
    setSampleModalOpen(!sampleModalOpen);
  };

  const mapResults = (csv) => {
    const results = csv.csv;
    if (results.errors && results.errors.length) {
      alert('Error');
    } else {
      if (results.data[0].length !== 3) {
        alert('Incorrect CSV format - should be "first_name","last_name","email"');
      } else {
        const usersToUpload = [];
        const csvInitialValues = {
          send_invitations: true,
        };
        for (let i = 0; i < results.data.length; i += 1) {
          const user = results.data[i];
          usersToUpload.push({
            first_name: user[0],
            last_name: user[1],
            email: user[2],
          });
          csvInitialValues[`user_${i}`] = true;
        }
        setResults(usersToUpload);
        setCsvInitialValues(csvInitialValues);
      }
    }
  };

  const refreshPendingClients = () => {
    doClearPendingClients();
    doGetPendingClients();
  };

  const goToUserDetail = (user) => {
    browserHistory.push({
      pathname: `/clients/${user.id}`,
      state: { from: 'Manage invites' }
    });
  };

  const renderAddClientsTab = () => {
    return (
      (
        <>
          <Row>
            <Col sm="12">
              <p>Step 1 is to upload your clients to your Macrostax Team account.</p>
              <p>
                Enter the name and email of each client that you would like to add to
                your Macrostax Team account. Select whether you’d like to send these
                clients an invite to join your Team now, or otherwise manage client
                invites later via the Manage Invites tab.{' '}
                <span className="span-link" onClick={toggleSampleModal}>
                  Learn more
                </span>
                .
              </p>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col xs={4}>First Name</Col>
            <Col xs={4}>Last Name</Col>
            <Col xs={4}>Email</Col>
          </Row>
          <Row>
            <Col sm="12">
              <AddClientForm
                showSubmissionError={showSubmissionError}
                onSubmit={(values, form, rows) => {
                  const newInitialValues = {
                    send_invitations: true,
                  };
                  for (let i = 0; i < rows; i += 1) {
                    newInitialValues[`first_name_${i}`] = '';
                    newInitialValues[`last_name_${i}`] = '';
                    newInitialValues[`email_${i}`] = '';
                  }
                  doAddClients(values, () => {
                    setTimeout(() => {
                      form.reset(newInitialValues);
                    });
                    toggleTab('invites');
                  });
                }}
                rows={5}
                initialValues={initialValues}
              />
            </Col>
          </Row>
        </>
      )
    );
  };

  const renderAddClientsCsvTab = () => {
    return (
      <>
        <Row>
          <Col sm={12}>
            <p>Step 1 is to upload your clients to your Macrostax Team account.</p>
            <p>
              Upload a CSV file to bulk add multiple clients at one time. You will
              have the ability to review the client list prior to adding each client
              to {team.settings?.name}.
            </p>
            <p>
              The CSV file should have 3 columns containing first name, last name,
              and email address (in that order), with no row headers. Check out this{' '}
              <a href="/users.csv">sample CSV file</a> for reference. Need help?
              Contact us through{' '}
              <span id="Intercom" className="span-link intercom">
                StaxChat
              </span>
              .
            </p>
            <p>
              You can also select whether you’d like to send your clients an invite
              to join your Team now, or manage invites via the Manage Invites tab
              later.{' '}
              <span className="span-link" onClick={toggleSampleModal}>
                Learn more
              </span>
              .
            </p>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col sm={4} xs={8} className="cursor-pointer">
            <CSVUploader onLoaded={(csv) => mapResults({ csv })} />
          </Col>
        </Row>
        {results && results.length ? (
          <AddClientsFromCsvForm
            showSubmissionError={showSubmissionError}
            onSubmit={(values) => {
                doAddClients(values, () => toggleTab('invites'));
              }}
            users={results}
            initialValues={csvInitialValues}
          />
          ) : null}
      </>
    );
  };

  const renderAddClientsDeepLinkTab = () => {
    const token = mainToken?.token;
    const showLink = !hiddenFeatures?.includes(BUTTONS[2].id) && !!token;

    return (
      <>
        <Row>
          <Col sm="12">
            <p>
              When sending this link, anyone that accesses it will be automatically added to your team with full access to everything as any other member.
              When turning the toggle off, the link will be disabled and if anyone tries to access it, they won't be able to join your team.
            </p>
          </Col>
        </Row>
        <CustomToggleForm
          initialValues={initialDeepLinkValues}
          doCustomToggle={(value) => {
            if (value) {
              doDeleteHiddenFeature('deepLink');
            } else {
              doAddHiddenFeature('deepLink');
            }
          }}
          isDisabled={false}
          name="deepLink"
          label=" Link to Join My Team"
        />
        {showLink && (
        <>
          <Row className="mt-3">
            <Col>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  name="deepLink"
                  value={shareTeamLink(token)}
                  disabled
                />

                <div className="input-group-append">
                  <Button
                    className="btn btn-outline-secondary"
                    type="button"
                    onClick={() => {
                      copyToClipboard(shareTeamLink(token), 'Team Link');
                    }}
                  >
                    <i className="fa fa-copy" />
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col sm="12">
              <p>
                Once a client joins your Team from your link they automatically have access to the app.  You can manage these clients below
              </p>
            </Col>
          </Row>
        </>
        )}
        <PendingApprovalDeepLinkForm
          showSubmissionError={showSubmissionError}
          team={team}
          onRowClick={(rowData) => goToUserDetail(rowData)}
        />
      </>

    );
  };

  const renderTabPane = () => {
    if (activeSub === BUTTONS[0].id) {
      return renderAddClientsTab();
    }

    if (activeSub === BUTTONS[1].id) {
      return renderAddClientsCsvTab();
    }

    if (activeSub === BUTTONS[2].id) {
      return renderAddClientsDeepLinkTab();
    }
  };

  useEffect(() => {
    if (window.location.hash) {
      const tabIndex = window.location.hash.replace('#', '');
      setTimeout(() => {
        if (tabButtons.map((b) => b.id).includes(tabIndex)) {
          toggleTab(tabIndex, false);
          return;
        }

        const button = BUTTONS.find((b) => b.id === tabIndex);
        if (button) {
          toggleTab(button.parentId, false);
          setActiveSub(tabIndex);
        }
      });
    }

    doGetInvitationTokens();
    doGetHiddenFeatures();
    doGetClientsRedeemed();
  }, [window.location.hash]);

  return (
    <ContentWrapper className="add-clients">
      <Row>
        <Col xs={12}>
          <PageTitle title="Add Clients" />
        </Col>
      </Row>
      {team && team.settings ? (
        <div className="main-content-container">
          <div className="d-md-none">
            <DropdownSubMenu
              buttons={tabButtons}
              title="Add Clients"
            />
          </div>
          <Row className="mt-4">
            <Col xs={12}>
              <Nav tabs className="d-none d-md-flex">
                {tabButtons.map((t) => (
                  <NavItem
                    key={t.id}
                    className={activeTab === t.id.toLowerCase() ? 'active' : ''}
                  >
                    <NavLink onClick={t.onClick}>
                      {t.icon && <em className={t.icon} />}
                      {t.label}
                    </NavLink>
                    <div className="tab-indicator animated" />
                  </NavItem>
                ))}
              </Nav>
            </Col>
          </Row>
          <TabContent activeTab={activeTab} className="animated no-top-margin">
            <TabPane tabId="addclients">
              <Row>
                <Col>
                  <div className="btn-group btn-group-toggle mt-4">
                    {BUTTONS.map((b) => (
                      <label
                        key={b.id}
                        className={`btn btn-secondary ${activeSub === b.id && 'active'}`}
                      >
                        <input
                          type="radio"
                          name="options"
                          id={b.id}
                          autoComplete="off"
                          defaultChecked={activeSub === b.id}
                          onClick={() => setActiveSub(b.id)}
                        />{' '}
                        {b.title}
                      </label>
                    ))}
                  </div>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  {renderTabPane(activeSub)}
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="invites">
              <Row className="mt-4">
                <Col sm="12">
                  {pendingClients ? (
                    <>
                      <p>Step 2 is to invite your clients to join your Macrostax Team.</p>
                      <p>
                        When you invite a client they will receive an email from Macrostax
                        prompting them to sign up to join your Team.{' '}
                        <span className="span-link" onClick={toggleSampleModal}>
                          Learn more
                        </span>{' '}
                        about this email.
                      </p>
                      <p>
                        This screen displays all clients you have uploaded to your Macrostax
                        account along with the status of their invitation. You can send, resend,
                        and remove invites below.
                      </p>
                      <>
                        <div className="mt-3">
                          <Row className="mt-6">
                            <Col xs={12}>
                              <ManageInvitesSearchForm
                                showSubmissionError={showSubmissionError}
                                onSubmit={searchPendingClients}
                                onReset={resetSearchPendingClients}
                                teamProfile={team.profile}
                                currentUser={user}
                                initialValues={initialValues}
                              />
                            </Col>

                            <Col md={6}>
                              <SideMenu />
                            </Col>
                          </Row>
                        </div>

                        <div className="mt-3">
                          <PendingClientsForm
                            showSubmissionError={showSubmissionError}
                            onSubmit={(values) => {
                              doSendInvitations(values);
                            }}
                            users={pendingClients ?? []}
                            initialValues={{}}
                            team={team}
                            onRowClick={(rowData) => goToUserDetail(rowData)}
                          />
                        </div>
                      </>
                    </>
                  ) : (
                    <Loading />
                  )}
                </Col>
              </Row>
            </TabPane>
          </TabContent>
          <SampleClientProfileModal isOpen={sampleModalOpen} toggle={toggleSampleModal} />
        </div>
      ) : (
        <Loading />
      )}
    </ContentWrapper>
  );
};

AddClients.propTypes = {
  showSubmissionError: PropTypes.func,
  doAddClients: PropTypes.func,
  user: PropTypes.instanceOf(Object),
  team: PropTypes.instanceOf(Object),
  doGetPendingClients: PropTypes.func,
  doClearPendingClients: PropTypes.func,
  doSendInvitations: PropTypes.func,
  searchPendingClients: PropTypes.func,
  resetSearchPendingClients: PropTypes.func,
  pendingClients: PropTypes.instanceOf(Array),
  doGetHiddenFeatures: PropTypes.func,
  hiddenFeatures: PropTypes.instanceOf(Array),
  clientsRedeemed: PropTypes.instanceOf(Array),
  doGetClientsRedeemed: PropTypes.func,
};
const mapStateToProps = (state) => ({
  team: state.team,
  pendingClients: state.team.pendingClients,
  user: state.auth.user,
  hiddenFeatures: state.team.hiddenFeatures,
  initialValues: {
    first_name: '',
    last_name: '',
    email: '',
    user_role_status: ['active', 'invited', 'pending', 'pre-active', 'onboarding'],
    ...state.context.clientsSearchForm,
  },
  initialDeepLinkValues: {
    deepLink: !state.team.hiddenFeatures?.includes('deepLink'),
  },
  clientsRedeemed: state.team.clientsRedeemed,
  mainToken: state.team.mainToken,
});
const mapDispatchToProps = (dispatch) => ({
  showSubmissionError: (errors) => dispatch(submissionError(errors)),
  doAddClients: (values, func) => dispatch(addClients(values, func)),
  doGetPendingClients: () => dispatch(getPendingClients()),
  doClearPendingClients: () => dispatch(clearPendingClients()),
  doSendInvitations: (values) => dispatch(sendInvitations(values)),
  doDeleteHiddenFeature: (id) => dispatch(deleteHiddenFeature(id)),
  doAddHiddenFeature: (id) => dispatch(addHiddenFeature(id)),
  doGetHiddenFeatures: () => dispatch(getHiddenFeatures()),
  doGetClientsRedeemed: () => dispatch(getClientsRedeemed()),
  doGetInvitationTokens: () => dispatch(getInvitationTokens()),
  searchPendingClients: (values) => dispatch(submitPendingClientsSearchRequest(values)),
  resetSearchPendingClients: () => {
    dispatch(clearClientsSearchForm());
    dispatch(submitPendingClientsSearchRequest());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddClients);
