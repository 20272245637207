import React, { useEffect, useMemo } from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import AuthRoute from './AuthRoute';
import Base from './components/Layout/Base';
import BasePreAuth from './components/Layout/BasePreAuth';
import BaseNoNav from './components/Layout/BaseNoNav';
import BasePrint from './components/Layout/BasePrint';
import browserHistory from './history';
import { getRolesForRoute, isTeamUser } from './utils/authUtils';
import { SIGNUP_FLOW_ROUTES, AUTH_NO_NAV_ROUTES } from './constants';

import Dashboard from './screens/Dashboard/Dashboard';
import Clients from './screens/Clients/Clients';
import ClientDetail from './screens/Clients/ClientDetail';
import AddClients from './screens/Clients/AddClients';
import UserDetail from './screens/Users/UserDetail';
import Groups from './screens/Clients/Groups';
import Settings from './screens/Settings/Settings';
import BusinessSettings from './screens/Settings/BusinessSettings';
import Admins from './screens/Settings/Admins';
import Billing from './screens/Settings/Billing';
import MenuLinks from './screens/AppContent/MenuLinks';
import HomeContent from './screens/AppContent/HomeContent';
import Alcohol from './screens/Utilities/Alcohol';
import Colors from './screens/Utilities/Colors';
import CSVUpload from './screens/Utilities/CSVUpload';
import FontIcons from './screens/Utilities/FontIcons';
import Recipes from './screens/Food/Recipes';
import RecipeDetail from './screens/Food/RecipeDetail';
import Foods from './screens/Food/Foods';
import TeamSearch from './screens/Team/TeamSearch';
import TeamDetail from './screens/Team/TeamDetail';
import TeamBranding from './screens/Team/TeamBranding';
import UserSearch from './screens/Users/UserSearch';
import Inbox from './screens/Settings/Inbox';
import LaunchGuide from './screens/Team/LaunchGuide';
import Challenges from './screens/Challenges/Challenges';
import ChallengeDetail from './screens/Challenges/ChallengeDetail';
import EditChallenge from './screens/Challenges/EditChallenge';
import TeamChallengeDetail from './screens/Challenges/TeamChallengeDetail';
import ChallengeClients from './screens/Challenges/ChallengeClients';
import AddChallenge from './screens/Challenges/AddChallenge';
import Referrals from './screens/ReferralProgram/Referrals';

import Unauthorized from './screens/Unauthorized';
import ResetPassword from './screens/NoNav/ResetPassword';

import Login from './screens/PreAuth/Login';
import SignUp from './screens/PreAuth/SignUp';
import SignUpBusiness from './screens/PreAuth/SignUpBusiness';
import SignUpPlans from './screens/PreAuth/SignUpPlans';
import SignUpBilling from './screens/PreAuth/SignUpBilling';
import SignUpComplete from './screens/PreAuth/SignUpComplete';
import ForgotPassword from './screens/PreAuth/ForgotPassword';
import SelectTeam from './screens/PreAuth/SelectTeam';
import LoadingScreen from './screens/PreAuth/Loading';

import PrintRecipe from './screens/Print/PrintRecipe';
import Integrations from './screens/Settings/Integrations';
import ContactIntegrationProvider from './screens/Integrations/ContactIntegrationProvider';
import IntegrationImportUsers from './screens/Integrations/IntegrationImportUsers';
import DashboardGroup from './screens/Dashboard/DashboardGroup';
import Chats from './screens/Chat/Chats';
import { getClientsRedeemed, getHiddenFeatures } from './actions/teamActions';

const timeout = { enter: 500, exit: 500 };
const animationName = 'rag-fadeIn';
const preAuthClassName = 'preauth';
const printClassName = 'print';

const Routes = ({ location,
  user,
  isLoggedIn,
  doGetHiddenFeatures,
  doGetClientsRedeemed,
  clientsRedeemed,
  hiddenFeatures,
  team }) => {
  const currentKey = location.pathname.split('/')[1] || '/';

  const pendingApprovalClients = useMemo(() => clientsRedeemed?.filter((client) => client.status === 'pending'), [clientsRedeemed]);

  useEffect(() => {
    if (!location.pathname.includes('print')) {
      doGetHiddenFeatures();
      doGetClientsRedeemed();
    }
  }, [location.pathname]);

  useEffect(() => {
    if (pendingApprovalClients && pendingApprovalClients?.length > 0 && (hiddenFeatures?.length > 0 && !hiddenFeatures?.includes('deepLink'))) {
      toast(`We have ${pendingApprovalClients.length} new clients pending for Approval!`, {
        type: 'success',
        position: 'top-center',
        autoClose: false,
        closeButton: true,
        toastId: 'clientsRedeemed',
        theme: 'colored',
        onClick: () => {
          browserHistory.push('/addclients#deeplink');
          toast.dismiss('clientsRedeemed');
        },
      });
    }
  }, [pendingApprovalClients, hiddenFeatures]);

  if (location.pathname.toLowerCase().indexOf('/print') === 0) {
    document.body.classList.remove(preAuthClassName);
    document.body.classList.add(printClassName);
    return (
      <BasePrint history={history}>
        <TransitionGroup>
          <CSSTransition key={location.key} timeout={500} classNames={animationName}>
            <div>
              <Switch location={location}>
                <Route path="/printrecipe/:recipe_id" exact component={PrintRecipe} />
              </Switch>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </BasePrint>
    );
  }
  if (SIGNUP_FLOW_ROUTES.indexOf(location.pathname) > -1) {
    document.body.classList.add(preAuthClassName);
    document.body.classList.remove(printClassName);
    return (
      <BasePreAuth>
        <TransitionGroup>
          <CSSTransition key={location.key} timeout={500} classNames={animationName}>
            <div>
              <Switch location={location}>
                <Route path="/login" component={Login} />
                <Route path="/signup/business" exact component={SignUpBusiness} />
                <Route path="/signup/plans" exact component={SignUpPlans} />
                <Route path="/signup/billing" exact component={SignUpBilling} />
                <Route path="/signup/complete" exact component={SignUpComplete} />
                <Route path="/signup" exact component={SignUp} />
                <Route path="/forgotpassword" exact component={ForgotPassword} />
                {currentKey !== 'signup' ? <Redirect to="/signup" /> : null}
                {currentKey !== 'login' ? <Redirect to="/login" /> : null}
              </Switch>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </BasePreAuth>
    );
  }
  if (AUTH_NO_NAV_ROUTES.indexOf(location.pathname) > -1) {
    document.body.classList.remove(preAuthClassName);
    document.body.classList.remove(printClassName);
    return (
      <BaseNoNav history={history}>
        <TransitionGroup>
          <CSSTransition key={location.key} timeout={500} classNames={animationName}>
            <div>
              <Switch location={location}>
                <Route path="/resetpassword" exact component={ResetPassword} />
                <Route path="/selectteam" exact component={SelectTeam} />
              </Switch>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </BaseNoNav>
    );
  }
  document.body.classList.remove(preAuthClassName);
  document.body.classList.remove(printClassName);
  return (
    <Base>
      <TransitionGroup>
        <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
          <div>
            <Switch
              location={location}
            >
              <Route path="/loading" exact component={LoadingScreen} />
              <AuthRoute
                exact
                path="/dashboard"
                component={Dashboard}
                roles={getRolesForRoute('dashboard')}
              />
              <AuthRoute
                exact
                path="/dashboard/group/:group_id"
                component={DashboardGroup}
                roles={getRolesForRoute('dashboard')}
              />
              <AuthRoute
                exact
                path="/clients"
                component={Clients}
                roles={getRolesForRoute('clientList')}
              />
              <AuthRoute
                path="/addclients"
                exact
                component={AddClients}
                roles={getRolesForRoute('addClients')}
              />
              <AuthRoute
                exact
                path="/groups"
                component={Groups}
                roles={getRolesForRoute('groups')}
              />
              <AuthRoute
                path="/clients/:user_id"
                exact
                component={ClientDetail}
                roles={getRolesForRoute('userDetail')}
              />
              <AuthRoute
                path="/settings"
                exact
                component={Settings}
                roles={getRolesForRoute('accountSettings')}
              />
              <AuthRoute
                path="/businesssettings"
                exact
                component={BusinessSettings}
                roles={getRolesForRoute('businessSettings')}
              />
              <AuthRoute
                path="/menulinks"
                exact
                component={MenuLinks}
                roles={getRolesForRoute('menuLinks')}
              />
              <AuthRoute
                path="/homecontent"
                exact
                component={HomeContent}
                roles={getRolesForRoute('homeContent')}
              />
              <AuthRoute
                path="/admins"
                exact
                component={Admins}
                roles={getRolesForRoute('adminUsers')}
              />
              <AuthRoute
                path="/integrations"
                exact
                component={Integrations}
                roles={getRolesForRoute('integrations')}
              />
              <AuthRoute
                path="/integrations/contact-integration-provider/:integration_id"
                exact
                component={ContactIntegrationProvider}
                roles={getRolesForRoute('integrations')}
              />
              <AuthRoute
                path="/integrations/import/:integration_id"
                exact
                component={IntegrationImportUsers}
                roles={getRolesForRoute('integrations')}
              />
              <AuthRoute
                path="/billing"
                exact
                component={Billing}
                roles={getRolesForRoute('billing')}
              />
              <AuthRoute
                path="/alcohol"
                exact
                component={Alcohol}
                roles={getRolesForRoute('alcoholCalculator')}
              />
              <AuthRoute
                path="/colors"
                exact
                component={Colors}
                roles={getRolesForRoute('colors')}
              />
              <AuthRoute
                path="/fonticons"
                exact
                component={FontIcons}
                roles={getRolesForRoute('fontIcons')}
              />
              <AuthRoute
                path="/csv"
                exact
                component={CSVUpload}
                roles={getRolesForRoute('csvUpload')}
              />
              <AuthRoute
                path="/recipes"
                exact
                component={Recipes}
                roles={getRolesForRoute('recipes')}
              />
              <AuthRoute
                path="/recipes/:recipe_id"
                exact
                component={RecipeDetail}
                roles={getRolesForRoute('recipes')}
              />
              <AuthRoute
                path="/foods"
                exact
                component={Foods}
                roles={getRolesForRoute('foods')}
              />
              <AuthRoute
                path="/teams"
                exact
                component={TeamSearch}
                roles={getRolesForRoute('teamSearch')}
              />
              <AuthRoute
                path="/teams/:team_id"
                exact
                component={TeamDetail}
                roles={getRolesForRoute('teamSearch')}
              />
              <AuthRoute
                path="/teams/:team_id/challenges/:challenge_id"
                exact
                component={TeamChallengeDetail}
                roles={getRolesForRoute('teamSearch')}
              />
              <AuthRoute
                path="/teambranding"
                exact
                component={TeamBranding}
                roles={getRolesForRoute('teamBranding')}
              />
              <AuthRoute
                path="/users"
                exact
                component={UserSearch}
                roles={getRolesForRoute('users')}
              />
              <AuthRoute
                path="/users/:user_id"
                exact
                component={UserDetail}
                roles={getRolesForRoute('userDetail')}
              />
              <AuthRoute
                path="/inbox"
                exact
                component={Inbox}
                roles={getRolesForRoute('inbox')}
              />
              <AuthRoute
                path="/launchguide"
                exact
                component={LaunchGuide}
                roles={getRolesForRoute('toolkit')}
              />
              <AuthRoute
                exact
                path="/challenges"
                component={Challenges}
                roles={getRolesForRoute('challenges')}
              />
              <AuthRoute
                path="/addchallenge"
                exact
                component={AddChallenge}
                roles={getRolesForRoute('addChallenge')}
              />
              <AuthRoute
                exact
                path="/challenges/users/:challenge_id"
                component={ChallengeClients}
                roles={getRolesForRoute('challengeClients')}
              />
              <AuthRoute
                exact
                path="/challenges/edit/:challenge_id"
                component={EditChallenge}
                roles={getRolesForRoute('addChallenge')}
              />
              <AuthRoute
                path="/challenges/:challenge_id"
                exact
                component={ChallengeDetail}
                roles={getRolesForRoute('challengeDetail')}
              />
              <AuthRoute
                path="/messages"
                exact
                component={Chats}
                roles={getRolesForRoute('chats')}
              />
              <AuthRoute
                path="/referrals"
                exact
                component={Referrals}
                roles={getRolesForRoute('referrals')}
              />
              <AuthRoute path="/unauthorized" exact component={Unauthorized} />

              {currentKey !== 'clients' && user && isTeamUser(user.roles) ? (
                <Redirect to="/clients" />
                ) : null}
              {currentKey !== 'users' && user && !isTeamUser(user.roles) ? (
                <Redirect to="/users" />
                ) : null}
              {currentKey !== 'login' && !isLoggedIn ? <Redirect to="/login" /> : null}
            </Switch>
          </div>
        </CSSTransition>
      </TransitionGroup>
    </Base>
  );
};

Routes.propTypes = {
  location: PropTypes.instanceOf(Object),
  user: PropTypes.instanceOf(Object),
  isLoggedIn: PropTypes.bool,
  hiddenFeatures: PropTypes.instanceOf(Array),
  team: PropTypes.instanceOf(Object),
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  isLoggedIn: state.auth.isLoggedIn,
  clientsRedeemed: state.team.clientsRedeemed,
  hiddenFeatures: state.team?.hiddenFeatures,
  team: state.team,
});

const mapDispatchToProps = (dispatch) => ({
  doGetHiddenFeatures: () => dispatch(getHiddenFeatures()),
  doGetClientsRedeemed: () => dispatch(getClientsRedeemed()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Routes));
